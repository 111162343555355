<template>
	<div class="main-content">
		<ktv-breadcrumb title="Field Agent" page="List" folder="Field Agent" />
		<table class="table table-hover ">
			<thead class="thead">
				<tr class="">
					<th>Action</th>
					<th>Entity ID</th>
					<th>Name</th>
					<th>Gender</th>
					<th>Access Role</th>
					<th>Country</th>
					<th>District</th>
					<th>Partner</th>
					<th>Trace</th>
					<th>User Created</th>
					<th>Reference Staff</th>
					<th>Modified By</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(a, key) in rows" :key="key">
					<td>
						<b-dropdown id="dropdown-1" text="" variant="link" class="m-md-2">
							<b-dropdown-item @click="openForm(a.EntityID, 'View')"><i class="fa fa-eye" aria-hidden="true"></i> &nbsp; View</b-dropdown-item>
							<b-dropdown-item @click="openForm(a.EntityID, 'Update')"><i class="fa fa-pencil" aria-hidden="true"></i> &nbsp; Update</b-dropdown-item>
						</b-dropdown>
					</td>
					<td>
						{{ a.EntityID }}
					</td>
					<td>{{ a.PersonName }}</td>
					<td>{{ a.Gender }}</td>
					<td>{{ a.PersonGender }}</td>
					<td>{{ a.PersonAccessRole }}</td>
					<td>{{ a.CountryName }}</td>
					<td>{{ a.DistrictName }}</td>
					<td></td>
					<td>{{ a.PersonCreated }}</td>
					<td>{{ a.ReferenceStaff }}</td>
					<td>{{ formatDate(a.DateModified) }}</td>
				</tr>
			</tbody>
		</table>	
		<paginationBarCustom 
			:state="paginationData" 
			@paginate="onPageChange()"
		/>

		<hr>

		<b-modal id="mAdd" title="BootstrapVue" hide-header-close no-close-on-backdrop hide-footer>
			<p class="my-4">Hello from modal!</p>
			<b-button class="mt-3" block @click="$bvModal.hide('mAdd')">Close Me</b-button>
		</b-modal>
	</div>
</template>

<script>

	import { mapActions } from "vuex"
	import paginationBarCustom from "@/components/paginationBarCustom"

	export default {
		metaInfo: {
			title: "FieldAgent",
		},
		components: {
			paginationBarCustom
		},
		data() {
			return {
				/* eslint-disable global-require */
				iconedit: require("@/assets/images/icon/iconedit.png"),
				icondelete: require("@/assets/images/icon/icondelete.png"),
				iconview: require("@/assets/images/icon/iconview.png"),
				icondropdown: require("@/assets/images/icon/icondropdown.png"),
				/* eslint-disable global-require */
				isLoading: false,
				modeTable: "remote",

				columns: [{
						label: "Action",
						field: "button",
						html: true,
						tdClass: "text-center",
						thClass: "text-center",
						sortable: false
					},
					{
						label: "Entity ID",
						field: "EntityID",
						thClass: "text-left",
					},
					{
						label: "Name",
						field: "PersonName",
						thClass: "text-left",
					},
					{
						label: "Gender",
						field: "PersonGender",
						thClass: "text-left",
					},
					{
						label: "Access Role",
						field: "PersonAccessRole",
						thClass: "text-left",
					},
					{
						label: "Country",
						field: "CountryName",
						thClass: "text-left",
					},
					{
						label: "District",
						field: "DistrictName",
						thClass: "text-left",
					},
					{
						label: "Partner",
						field: "DistrictName",
						thClass: "text-left",
					},
					{
						label: "Trace",
						field: "DistrictName",
						thClass: "text-left",
					},
					{
						label: "User Created",
						field: "PersonCreated",
						thClass: "text-left",
					},
					{
						label: "Reference Staff",
						field: "ReferenceStaff",
						thClass: "text-left",
					},
					{
						label: "Modified By",
						field: "DateModified",
						thClass: "text-left",
					},
				],

				rows: [],
				totalRecords: 0,
				serverParams: {
					columnFilters: {
						SubCategoryLanguageText: "",
					},
					sort: [
						{
							field: "",
							type: "ASC",
						},
					],
					currentPage: 1,
					rowPerpage: 10,
				},
				paginationData: {
					pagination: {
						current_page: 1
					},
				},
			}
		},
		computed: {

		},
		mounted() {
			this.loadItems()
		},
		methods: {
			...mapActions("FIELDAGENT", ["pushData"]),

			openForm(item, opsi ) {
				let data = {
					EntityID: item,
					opsiDisplay: opsi,					
				}
				this.pushData(data);
				this.$router.push('detailFieldAgent')

				// if(opsi === 'view'){
				// 	this.$router.push(`detailFieldAgent?id=${id}&act=view`)
				// }

				// if(opsi === 'update'){
				// 	this.$router.push(`detailFieldAgent?id=${id}&act=update`)
				// }
			},
			// load items is what brings back the rows from server
			loadItems() {
				this.$swal.fire({
					title: 'Loading...',
					allowOutsideClick: false,
					onBeforeOpen: () => {
						this.$swal.showLoading()
						this.$http
							.Post(this.serverParams, this.$urlApi.fieldAgent.list)
							.then((response) => {
								this.isLoading = false
								if (response.success) {
									this.rows = response.results.data
									this.totalRecords = response.results.total
									this.paginationData.pagination = response.results
								} else {
									this.$swal("Error!", response.error_massage, "error")
								}
							})
							.catch((error) => {
								this.$swal("Error!", error.response, "error")
							}).finally(() => this.$swal.close())
					}
				})	

				this.updateParams({ currentPage: 1 })
			},
			updateParams(newProps) {
				this.serverParams = Object.assign({}, this.serverParams, newProps)
			},
			onPageChange() {
				this.updateParams({ currentPage: this.paginationData.pagination.current_page })
				this.loadItems()
			},
			onPerPageChange(params) {
				this.updateParams({ rowPerpage: params.currentPerPage })
				this.loadItems()
			},
			onSortChange(params) {
				this.updateParams({
					sort: [
						{
							type: params[0].type,
							field: params[0].field,
						},
					],
					currentPage: 1
				})
				this.loadItems()
			},
			onColumnFilter(params) {
				this.updateParams(params)
				this.loadItems()
			},
			onSearchFilter() {
				this.updateParams({
				})
				this.loadItems()
			},
		}
	}
</script>

<style>
.dropdownitem-action:hover a,
.dropdownitem-action:focus a {
	background-color: #CAEAFF !important;
}
</style>